<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header">
                <div class="content__header-left">
                    <h1 class="heading-page-h1">{{ $translate('devices') }}</h1>
                </div>
                <div class="content__header-right">
                    <button type="button" class="btn btn-primary btn-standart" @click="openAddDevicePopup">
                        <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)"/>
                        <span>{{ $translate('add-iot-device') }}</span>
                    </button>
                </div>
            </div>

			<TableAdvanced v-model="sortCol" :rows="filteredRows" :columns="columns" :isCheckedAll="isCheckedAll" :checkedInputs="checkedInputs" @toggleAllCheckedInputs="toggleAllCheckedInputs" v-if="!loading">
				<template v-slot:filters>
					<div class="filter-options__search">
						<input type="search" 
							class="input bg-left search-icon" 
							:placeholder="$translate('search')" 
							v-model="rowsFilters.search"
						/>
					</div>
					<div class="filter-options__items flex-x-wrap flex-auto">
						<DropdownAdvanced
							selectAll="Categories"
							:options="deviceCategoriesOptions"
							v-model="rowsFilters.categoryName"
						/>
						<!-- <DropdownAdvanced
							selectAll="Locations"
							:options="locationOptions"
							v-model="rowsFilters.locationName"
						/> -->
						<DropdownAdvanced
							selectAll="Status"
							:options="statusOptions"
							v-model="rowsFilters.status"
						/>
						<DropdownAdvanced
							selectAll="Type"
							:options="deviceTypes"
							v-model="rowsFilters.device_type"
						/>
					</div>
					<!-- <router-link class="btn btn-w-shadow btn-standart-2" to="/map/devices">
						<inline-svg :src="require(`@/assets/img/ico-map.svg`)"/>
						<span>{{ $translate('map') }}</span>
					</router-link> -->
				</template>

				<template v-slot:row="slotData">
					<td>
						<label class="module__check">
                            <input type="checkbox" name="category" :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event,slotData.r.id)"/>
							<span class="check"></span>
							<router-link :to="slotData.r.device_from_iot_data ? `/devices-dynamo/${slotData.r.id}` : `/devices/${slotData.r.id}`" style="text-decoration: underline;color: var(--text-primary);">
								<span class="text">{{ slotData.r.name }}</span>
							</router-link>
						</label>
					</td>
					<td>
						<div class="flex icon-text icon-sm">
							<DeviceIcon :category="slotData.r.category" :isDynamoDbDevice="!!slotData.r.device_iot_data_id" v-if="!slotData.r.device_from_iot_data"/>
							<div class="device_table_dynamo_icon" v-else>
								<svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
									<path xmlns="http://www.w3.org/2000/svg" d="M5.32812 5.46612C6.96881 3.95349 9.3448 2.99609 11.9979 2.99609C14.651 2.99609 17.027 3.95349 18.6677 5.46612" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
									<path xmlns="http://www.w3.org/2000/svg" d="M7.55273 8.18328C8.64619 7.2759 10.2298 6.70166 11.9986 6.70166C13.7673 6.70166 15.351 7.2759 16.4444 8.18328" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
									<path xmlns="http://www.w3.org/2000/svg" d="M9.77539 10.9995C10.3226 10.5453 11.1139 10.2582 11.9983 10.2582C12.8827 10.2582 13.675 10.5453 14.2212 10.9995" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
									<path xmlns="http://www.w3.org/2000/svg" d="M4.40122 10.9995L3.04765 17.7663C2.71251 19.441 3.99405 21.0037 5.70276 21.0037H18.295C20.0027 21.0037 21.2842 19.441 20.9491 17.7663L19.5955 10.9995" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
								</svg>
							</div>
							<span>{{ slotData.r.device_from_iot_data ? 'Dynamo DB Device' : slotData.r.categoryName || '-' }}</span>
						</div>
					</td>
					<td>{{ slotData.r.date_of_purchase || '-' }}</td>
					<td>{{ slotData.r.device_type || '-' }}</td>
					<td>
						<div v-if="slotData.r.locationName" class="flex icon-text">
							<inline-svg :src="require(`@/assets/img/Pin-Location.svg`)"/>
							<span>{{ slotData.r.locationName || '-' }}</span>
						</div>
						<div 
							v-else-if="slotData.r.paddockID && paddocks.find(pItemF => pItemF.id === slotData.r.paddockID) && 
							((paddocks.find(pItemF => pItemF.id === slotData.r.paddockID).map_info.center_lat && paddocks.find(pItemF => pItemF.id === slotData.r.paddockID).map_info.center_lng) ||
								paddocks.find(pItemF => pItemF.id === slotData.r.paddockID).map_info.points
							)" class="flex icon-text"
						>
							<inline-svg :src="require(`@/assets/img/Pin-Location.svg`)"/>
							{{ devicesLocation.length && devicesLocation.find(itemDL => itemDL.id === slotData.r.id) ? devicesLocation.find(itemDL => itemDL.id === slotData.r.id).label : '-' }}
						</div>
						<span v-else>-</span>
					</td>
					<!-- <td>{{ slotData.r.device_info||'-' }}</td> -->
					<td style="padding-right: 50px">
						<span class="table-statys" :class="slotData.r.status">
							<span class="dot"></span> {{ slotData.r.status }}
						</span>
					</td>
					<td class="position-stiky" v-if="!slotData.r.device_iot_data_id">
                        <div class="table-options-drop dropdown-wrapper">
                            <button
                                type="button" class="btn btn-sm btn-transp dropdown-btn" 
                                :class="{'active':menuOpen.open === slotData.r.id }"
                                @click="openOptions($event, slotData.r.id)"
                            >
                                <inline-svg :src="require('@/assets/img/dots-menu.svg')" class="pointer-events-none" />
                            </button>
                            <div 
                                class="categories__dropdown dropdown-content" 
                                :class="{'active':menuOpen.open === slotData.r.id }"
                                style="position: fixed;"
                                :style="{ left: menuOpen.left, top: menuOpen.top, bottom: 'auto' }"
                            >
                                <ul>
									<li>
										<router-link :to="`/devices/${slotData.r.id}?tab=info`">{{ $translate('edit') }}</router-link>
									</li>
									<li>
										<router-link :to="`/devices/${slotData.r.id}`">{{ $translate('view-device') }}</router-link>
									</li>
									<li>
										<router-link :to="`/devices/${slotData.r.id}?tab=rules`">{{ $translate('set-rules') }}</router-link>
									</li>
									<li>
										<router-link :to="`/devices/${slotData.r.id}?tab=schedule`">{{ $translate('schedule') }}</router-link>
									</li>
									<li>
										<router-link :to="`/devices/${slotData.r.id}?tab=report`">{{ $translate('report') }}</router-link>
									</li>
									<li class="list-line"></li>
									<li>
										<button type="button" class="red-color" @click="confirmDelete(slotData.r.id,'Delete device?')">
											{{ $translate('delete-device') }}
										</button>
									</li>
                                </ul>
                            </div>
                        </div>

                    </td>
				</template>	
			</TableAdvanced>
			<div style="min-height: 300px;" class="processing" v-else></div>
        </main>

		<AddIOTDevice
			v-if="addDevicePopup" 
			@close="closeAddDevicePopup" 
			@submit="addDeviceSubmit" 
		/>

       	<Pagination v-model="filteredRows" :items="rawSource" />
	
    </div>
</template>

<script>
import { API } from 'aws-amplify';

import { 
	createDevice,
	updateDevice
} from '@/graphql/mutations';

import Base from "@/components/base";
import Popups from '@/components/popups';

import { mixTables,mixDeleteItem } from '@/mixins';
import { devCats, devTypes, devStatus } from '@/config';

export default {
    name: "Devices",
	mixins: [mixTables, mixDeleteItem],
    components: {
        ...Base,
		...Popups
    },
    data() {
        return {
			source: 'devices',
			columns: [
				{ name: 'Device Name', filter: 'name' },
				{ name: 'Category', filter: 'categoryName' },
				{ name: 'Date of Purchase', filter: 'date_of_purchase' },
				{ name: 'Type', filter: 'device_type' },
				{ name: 'Location', filter: 'locationName' },
				// { name: 'Device Info', filter: 'device_info' },
				{ name: 'Status', filter: 'status', colspan: 2 }
			],
			calculatedFields:[
				{
					filter: 'locationName',
					path: ['location'],
					calcFunction: (location)=>location.address || null
				},
				{
					filter: 'categoryName',
					path: [],
					calcFunction: (device)=>device.category=="custom_category"?device.custom_category:devCats.find(o=>o.id===device.category)?.name
				}
			],
			addDevicePopup: false,
			deleteQueryType: "deleteDevice",
			menuOpen: {
                left: '-500px',
                top: 0,
                open: null,
            },
			devicesLocation: [],
			loading: true,
			getDevicesLocationCount: 0,
        };
    },
	computed: {
		sourceData(){
            return [...this.$store.state.devices,...this.$store.state.iotDevices]
        },
		deviceCategoriesOptions(){
			let categoriesMain=new Set();
			let categoriesCustom=new Set();
			this.sourceData.forEach(d=>{
				if(d.category=='custom_category'){
					categoriesCustom.add(d.custom_category)
				}else{
					categoriesMain.add(d.category)
				}
			})
			// console.log(categoriesMain,categoriesCustom);
			let categories=[]
			categoriesMain.forEach(deviceCategory=>{		
				let categoryData={...devCats.find(category=>category.id===deviceCategory)}
				categoryData.id=categoryData.name
				categories.push(categoryData)
			})
			categoriesCustom.forEach(deviceCategory=>{
				categories.push({id:deviceCategory,name:deviceCategory})
			})
            return categories
		},
		deviceCategories() {
			let categoriesMain=new Set();
			let categoriesCustom=new Set();
			this.sourceData.forEach(d=>{
				if(d.category=='custom_category'){
					categoriesCustom.add(d.custom_category)
				}else{
					categoriesMain.add(d.category)
				}
			})
			// console.log(categoriesMain,categoriesCustom);
			let categories=[]
			categoriesMain.forEach(deviceCategory=>{		
				categories.push(devCats.find(category=>category.id==deviceCategory))
			})
			categoriesCustom.forEach(deviceCategory=>{
				categories.push({id:deviceCategory,name:deviceCategory})
			})
            return categories
        },
		deviceTypes() {
			return devTypes
		},
		statusOptions() {
            return [
                ...Object.keys(devStatus)
                .filter(opt => opt !== 'true' && opt !== 'false')
                .map(o => ({ id: o, name: o }))
            ]
        },
		locationOptions(){
			let set=new Set()
			this.$store.state.devices.forEach(device=>{
				if(device?.location?.address){
					set.add(device.location.address)
				}
			})
			return Array.from(set).map(locationName=>{
				return {id:locationName,name:locationName}
			})
		},
		paddocks(){
			return this.$store.state.paddocks;
		},
	},
    methods: {
		async geocodeReverse(cords) {
			let query = Object.values(cords).join();
			const { data } = await this.$provider.positionStack.getReverse(query);

			if(data.length) {
				console.log(data[0]);
				let areaLabel = JSON.parse(JSON.stringify(data[0].administrative_area));
				return areaLabel;
			}
		},
		async getDevicesLocation(){
			if((!this.paddocks.length || !this.sourceData.length) && this.getDevicesLocationCount < 5){
				setTimeout(() => {
					this.getDevicesLocation();
					this.getDevicesLocationCount++;
				}, 1000);
				return;
			}

			this.sourceData.forEach(async device => {
				if(!device.locationName && device.paddockID && this.paddocks.find(pItemF => pItemF.id === device.paddockID) && 
					((this.paddocks.find(pItemF => pItemF.id === device.paddockID).map_info.center_lat && this.paddocks.find(pItemF => pItemF.id === device.paddockID).map_info.center_lng) ||
					this.paddocks.find(pItemF => pItemF.id === device.paddockID).map_info.points)){
					let gettedData = await this.geocodeReverse(this.paddocks.find(pItemF => pItemF.id === device.paddockID).map_info.center_lat ? { 
						"lat": this.paddocks.find(pItemF => pItemF.id === device.paddockID).map_info.center_lat,
						"lng": this.paddocks.find(pItemF => pItemF.id === device.paddockID).map_info.center_lng
					} : this.paddocks.find(pItemF => pItemF.id === device.paddockID).map_info.points[0]);

					if(!this.devicesLocation.length || !this.devicesLocation.map(item => item.id).includes(device.id)) {
						this.devicesLocation.push({
							id: device.id,
							label: gettedData
						})
					}
				}
			});

			this.loading = false;
		},
		showOnMap() {
			console.log('show on map');
		},
        openOptions(ev, id) {
            if(this.menuOpen.open === id){
                this.menuOpen.open = null;
                this.menuOpen = {
                    left: '-500px',
                    top: 0,
                    open: null,
                }
            } else {
                this.menuOpen.open = id;
                setTimeout(() => {
                    let targetClientRect = ev.target.getBoundingClientRect();

                    this.menuOpen.left = (targetClientRect.x - ev.target.nextElementSibling.offsetWidth + 22) + 'px';

                    if((targetClientRect.top + targetClientRect.height + ev.target.nextElementSibling.offsetHeight+70) > document.querySelector('#app').offsetHeight){
                        this.menuOpen.top = ((targetClientRect.y - ev.target.nextElementSibling.offsetHeight) - 10) + 'px';
                    } else {
                        this.menuOpen.top = (targetClientRect.y + 15) + 'px';
                    }
                }, 10);
            }
        },
        outsideMenuClick(e){
            if(this.menuOpen.open&&!e.target.closest('.table-options-drop.dropdown-wrapper')){
                this.menuOpen.open = null;
            }
        },
		openAddDevicePopup() {
			this.addDevicePopup = true;
		},
		closeAddDevicePopup() {
			this.addDevicePopup = false;
		},
		async addDeviceSubmit(ev) {
			const input = ev;
			
			const device = await API.graphql({
				query: createDevice,
				variables: { input }
			}).then(res => res.data.createDevice)

			// this.$store.commit('ADD_ITEM', { field: 'devices', item: device });

			this.closeAddDevicePopup();

			const options = { title: 'Add IOT Device', size: 'sm' }
			this.$dialogs.alert('Your device will be onboarded to your dashboard within the next 24 hours if you have sensors included in your package.', options)
				.then(res => {
					console.log(res) // {ok: true|false|undefined}
				})
		},
		// deleteDevice(ID) {
		// 	console.log(ID);
		// }
    },
	async created() {
        let self = this;
        window.addEventListener('click', self.outsideMenuClick);
    },
	async mounted() {
		this.getDevicesLocation();
    },
    beforeDestroy(){
        let self = this;
        window.removeEventListener('click', self.outsideMenuClick);
    }
};
</script>
